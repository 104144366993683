import { Form, InputNumber } from "antd";

export default function FormInput(props) {
  return (
    <div {...props} className="grid grid-cols-12 gap-3">
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Form.Item
          name={["data", "promoted_articles", "m01"]}
          className="w-full"
          label="Продвигаемый материал (M01)"
        >
          <InputNumber
            placeholder="Введите ID материала"
            className="w-full"
            min={1}
            controls={false}
          />
        </Form.Item>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Form.Item
          name={["data", "promoted_articles", "m02"]}
          className="w-full"
          label="Продвигаемый материал (M02)"
        >
          <InputNumber
            placeholder="Введите ID материала"
            className="w-full"
            min={1}
            controls={false}
          />
        </Form.Item>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Form.Item
          name={["data", "promoted_articles", "m03"]}
          className="w-full"
          label="Продвигаемый материал (M03)"
        >
          <InputNumber
            placeholder="Введите ID материала"
            className="w-full"
            min={1}
            controls={false}
          />
        </Form.Item>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3">
        <Form.Item
          name={["data", "promoted_articles", "m04"]}
          className="w-full"
          label="Продвигаемый материал (M04)"
        >
          <InputNumber
            placeholder="Введите ID материала"
            className="w-full"
            min={1}
            controls={false}
          />
        </Form.Item>
      </div>
    </div>
  );
}
