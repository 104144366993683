import { useState } from "react";
import { Card, Form, Input, Button, Divider } from "antd";
import classNames from "classnames";
import {
  ArrowLeftOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function LoginForm(props) {
  const [resetting, setResetting] = useState(false);
  const { loading, login, resetPassword, currentUser, isVerified } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onLogin = async (data) => {
    data.token = await executeRecaptcha();
    await login(data);
  };

  const onReset = async (data) => {
    data.token = await executeRecaptcha();
    await resetPassword(data);
  };

  return (
    <Form
      hidden={currentUser && !isVerified}
      tabIndex={1}
      className="login-form !mb-0"
      name="signin"
      size="large"
      initialValues={{ remember: true }}
      onFinish={resetting ? onReset : onLogin}
      {...props}
    >
      <h1 className="text-3xl font-semibold text-black leading-normal mb-0">
        {resetting ? "Восстановление доступа" : "Добро пожаловать"}
      </h1>
      <span className="block text-lg font-normal mb-5 text-gray-700">
        {resetting
          ? "Введите вашу почту, чтобы начать процесс восстановления доступа"
          : "Пожалуйста, авторизуйтесь"}
      </span>
      <Form.Item
        name="email"
        className="my-4"
        rules={[
          {
            required: true,
            message: "Пожалуйста, введите вашу почту",
          },
          {
            type: "email",
            validateTrigger: "onSubmit",
            message: "Почта введена неверно",
          },
        ]}
      >
        <Input
          autoCapitalize="false"
          disabled={loading}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Эл.почта"
        />
      </Form.Item>
      <Form.Item
        hidden={resetting}
        name="password"
        className="my-4"
        rules={[
          {
            min: 6,
            required: !resetting,
            message: "Пожалуйста, введите пароль",
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Пароль"
          disabled={loading}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="admin-login-form-button"
          loading={loading}
          block
        >
          {resetting ? "Отправить" : "Войти"}
        </Button>
      </Form.Item>
      <Form.Item className="!mb-0" style={{ fontSize: 15 }}>
        <div className="flex justify-between items-center w-full">
          <Button
            className="m-0 p-0"
            type="link"
            disabled={loading}
            hidden={resetting}
            onClick={() => setResetting(true)}
          >
            Забыли пароль?
          </Button>
          <Button
            className="m-0 p-0"
            type="link"
            hidden={!resetting}
            disabled={loading}
            onClick={() => setResetting(false)}
            icon={<ArrowLeftOutlined />}
          >
            Назад
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}
