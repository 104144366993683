import "./index.css";
import { IconAlignLeft, IconAlignCenter, IconQuote } from "@codexteam/icons";
import { make } from "@editorjs/dom";
import parallaxIcon from "../image/svg/parallax";

/**
 * Enum for Quote Alignment
 */
const Alignment = {
  Left: "left",
  Center: "center",
  Vertical: "vertical line",
};

/**
 * @class Quote
 * @classdesc Quote Tool for Editor.js
 */
export default class Quote {
  /**
   * Render plugin's main Element and fill it with saved data
   * @param {object} params - constructor params
   * @param {object} params.data - previously saved data
   * @param {object} params.config - user config for Tool
   * @param {object} params.api - editor.js api
   * @param {boolean} params.readOnly - read only mode flag
   */
  constructor({ data, config, api, readOnly, block }) {
    const DEFAULT_ALIGNMENT = Alignment.Left;

    this.api = api;
    this.readOnly = readOnly;

    this._quotePlaceholder =
      config.quotePlaceholder || Quote.DEFAULT_QUOTE_PLACEHOLDER;
    this._captionPlaceholder =
      config.captionPlaceholder || Quote.DEFAULT_CAPTION_PLACEHOLDER;
    this._extraCaptionPlaceholder =
      config.extraCaptionPlaceholder || "Введите доп. автора/источник";

    this._data = {
      text: data.text || "",
      caption: data.caption || "",
      extracaption: data.extracaption || "",
      alignment:
        (Object.values(Alignment).includes(data.alignment) && data.alignment) ||
        config.defaultAlignment ||
        DEFAULT_ALIGNMENT,
    };
    this._CSS = {
      baseClass: this.api.styles.block,
      wrapper: "cdx-quote",
      text: "cdx-quote__text",
      input: this.api.styles.input,
      caption: "cdx-quote__caption",
      extracaption: "cdx-quote__caption__extra",
    };
    this._block = block;
  }

  static get tunes() {
    return [
      {
        name: "verticalArrow",
        icon: parallaxIcon,
        title: "Вертикальная стрелка",
        toggle: true,
      },
    ];
  }

  /**
   * Notify core that read-only mode is supported
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      icon: IconQuote,
      title: "Quote",
    };
  }

  /**
   * Empty Quote is not empty Block
   * @returns {boolean}
   */
  static get contentless() {
    return true;
  }

  /**
   * Allow to press Enter inside the Quote
   * @returns {boolean}
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Default placeholder for quote text
   * @returns {string}
   */
  static get DEFAULT_QUOTE_PLACEHOLDER() {
    return "Enter a quote";
  }

  /**
   * Default placeholder for quote caption
   * @returns {string}
   */
  static get DEFAULT_CAPTION_PLACEHOLDER() {
    return "Enter a caption";
  }

  /**
   * Default quote alignment
   * @returns {string}
   */
  static get DEFAULT_ALIGNMENT() {
    return Alignment.Left;
  }

  /**
   * Allow Quote to be converted to/from other blocks
   */
  static get conversionConfig() {
    return {
      import: "text",
      export: function (quoteData) {
        return quoteData.caption
          ? `${quoteData.text} — ${quoteData.caption}`
          : quoteData.text;
      },
    };
  }

  /**
   * Tool's settings properties
   * @returns {*[]}
   */
  get settings() {
    return [
      {
        name: Alignment.Left,
        icon: IconAlignLeft,
      },
      {
        name: Alignment.Center,
        icon: IconAlignCenter,
      },
      {
        name: Alignment.Vertical,
        icon: parallaxIcon,
      },
    ];
  }

  /**
   * Create Quote Tool container with inputs
   * @returns {Element}
   */
  render() {
    const container = make("blockquote", [
      this._CSS.baseClass,
      this._CSS.wrapper,
    ]);
    const quote = make("div", [this._CSS.input, this._CSS.text], {
      contentEditable: !this.readOnly,
      innerHTML: this._data.text,
    });

    const caption = make("div", [this._CSS.input, this._CSS.caption], {
      contentEditable: !this.readOnly,
      innerHTML: this._data.caption,
    });

    const extracaption = make(
      "div",
      [this._CSS.input, this._CSS.extracaption],
      {
        contentEditable: !this.readOnly,
        innerHTML: this._data.extracaption,
      }
    );

    quote.dataset.placeholder = this._quotePlaceholder;
    caption.dataset.placeholder = this._captionPlaceholder;
    extracaption.dataset.placeholder = this._extraCaptionPlaceholder;

    container.appendChild(quote);
    container.appendChild(caption);
    container.appendChild(extracaption);
    return container;
  }

  /**
   * Extract Quote data from Quote Tool element
   * @param {HTMLDivElement} quoteElement - element to save
   * @returns {object}
   */
  save(quoteElement) {
    const text = quoteElement.querySelector(`.${this._CSS.text}`);
    const caption = quoteElement.querySelector(`.${this._CSS.caption}`);
    const extracaption = quoteElement.querySelector(
      `.${this._CSS.extracaption}`
    );

    return Object.assign(this._data, {
      text: text?.innerHTML ?? "",
      caption: caption?.innerHTML ?? "",
      extracaption: extracaption?.innerHTML ?? "",
    });
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      text: {
        br: true,
      },
      caption: {
        br: true,
      },
      extracaption: {
        br: true,
      },
      alignment: {},
    };
  }

  /**
   * Create wrapper for Tool's settings buttons
   * @returns {HTMLElement | object}
   */
  renderSettings() {
    const capitalize = (str) =>
      str ? str[0].toUpperCase() + str.slice(1) : str;

    return this.settings.map((item) => ({
      icon: item.icon,
      label: this.api.i18n.t(`Align ${capitalize(item.name)}`),
      onActivate: () => this._toggleTune(item.name),
      isActive: this._data.alignment === item.name,
      closeOnActivate: true,
    }));
  }

  /**
   * Toggle quote's alignment
   * @param {string} tune - alignment
   * @private
   */
  _toggleTune(tune) {
    this._data.alignment = tune;

    // Dispatch change if quoteElement already exists
    this._block.dispatchChange();
  }
}
