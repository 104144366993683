import { Alert } from "antd";

export default function Widget({ input, onChange, key, data }) {
  return (
    <div className="col-span-12">
      Test
      <Alert
        type="success"
        showIcon
        className="w-full"
        description="Нажмите «Добавить», чтобы вставить виджет на страницу."
      />
    </div>
  );
}
