import { Projects, OfflineWidget } from "@modules";
import { Breadcrumb } from "@components";
import { useParams } from "react-router-dom";

export default function ProductPage() {
  const { id } = useParams();

  return (
    <div className="container max-content">
      <Breadcrumb
        data={[
          { title: "Спецпроекты", href: "/projects" },
          { title: "Редактировать проект", href: `/projects/edit/${id}` },
        ]}
      />
      <h1 className="mb-5 lg:mb-8 text-xl lg:text-3xl">Редактировать проект</h1>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Projects.Edit id={id} />
        </div>
      </div>
      <OfflineWidget />
    </div>
  );
}
