import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AuthContextProvider from "@contexts";
import config from "@config";
import ruRU from "antd/lib/locale/ru_RU";
import dayjs from "dayjs";
import { ConfigProvider } from "antd";
import "dayjs/locale/ru";
import "antd/dist/reset.css";
import "@styles/global.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
dayjs.locale("ru");

root.render(
  <ConfigProvider {...config.antd} locale={ruRU}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </ConfigProvider>
);
