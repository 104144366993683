import Name from "./name";
import Email from "./email";
import EmailConfirmed from "./email_confirmed";
import Blocked from "./blocked";
import Role from "./role";
import { Divider } from "antd";
import dayjs from "dayjs";

export default function Details(props) {
  const createdAt = props.form.getFieldValue("created_at");
  const lastLoginAt = props.form.getFieldValue("last_login_at");
  const regIp = props.form.getFieldValue("registration_ip");
  const lastIp = props.form.getFieldValue("last_login_ip");

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <Email {...props} />
      </div>
      <div className="col-span-12">
        <EmailConfirmed {...props} />
      </div>
      <div className="col-span-12">
        <Blocked {...props} />
      </div>
      <div className="col-span-12">
        <Role {...props} />
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
      <div className="col-span-12">
        <span className="block w-full text-sm font-normal mb-1">
          IP регистрации
        </span>
        <span className="block w-full text-sm font-normal text-neutral-500">
          {regIp || "-"}
        </span>
      </div>
      <div className="col-span-12">
        <span className="block w-full text-sm font-normal mb-1">
          IP посл. входа
        </span>
        <span className="block w-full text-sm font-normal text-neutral-500">
          {lastIp || "-"}
        </span>
      </div>
      <div className="col-span-12">
        <span className="block w-full text-sm font-normal mb-1">
          Посл. вход
        </span>
        <span className="block w-full text-sm font-normal text-neutral-500">
          {lastLoginAt ? dayjs(lastLoginAt).format("HH:mm DD.MM.YYYY") : "-"}
        </span>
      </div>
      <div className="col-span-12">
        <span className="block w-full text-sm font-normal mb-1">
          Зарегистрирован
        </span>
        <span className="block w-full text-sm font-normal text-neutral-500">
          {dayjs(createdAt).format("HH:mm DD.MM.YYYY")}
        </span>
      </div>
      <div className="col-span-12">
        <Divider className="my-2" />
      </div>
    </div>
  );
}
