import { useEffect, useState } from "react";
import { message, Select, Spin, Form } from "antd";
import debounce from "debounce";
import * as API from "@api";

export default function FormInput(params) {
  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const data = params?.form?.getFieldValue("tags") || [];

    setSelected(
      data?.map((item) => ({
        label: item.name,
        value: item.id,
        data: item,
      }))
    );

    if (data.length) {
      API.Tags.getAll({
        limit: data?.length || 10,
        filter: { id: data?.map((item) => item?.id) },
      })
        .then((res) => {
          setInitialData(res?.data?.rows || []);
        })
        .finally(() => setLoading(false));

      return;
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    params.form.setFieldsValue({ tags: selected });
  }, [selected]);

  useEffect(() => {
    if (!initialData?.length) {
      return;
    }

    setOptions(
      initialData?.map((item) => ({
        value: item.id,
        label: item.name,
        data: item,
      }))
    );
  }, [initialData]);

  let fetchData = async (value) => {
    setOptions([]);
    setFetching(true);
    const data = await API.Tags.getAll({
      limit: 10,
      filter: { name: value },
    });

    if (!data || data?.error) {
      return message.error(
        "Невозможно произвести поиск, проверьте интернет-соединение",
        3
      );
    }

    setOptions(
      data?.data?.rows?.map((item) => ({
        value: item.id,
        label: item.name,
        data: item,
      }))
    );

    setFetching(false);
  };

  fetchData = debounce(fetchData, 200);

  if (loading) return null;

  return (
    <Form.Item
      {...params}
      className="w-full"
      label="Теги"
      tooltip="Начните вводить название тега и выберите из предложенных вариантов. Если тег не найден, просто нажмите Enter. Тег создастся автоматически и прикрепится к полю."
    >
      <Select
        mode="tags"
        onSelect={async (newTag, data) => {
          // if tag not found create a tag in backend and return it
          if (!data?.value && newTag) {
            const tag = await API.Tags.create({
              name: newTag,
            }).then((data) => data?.data);

            if (!tag) {
              return message.error(
                "Ошибка при создании тега, попробуйте снова",
                2
              );
            }

            return setSelected([
              ...selected,
              { label: tag.name, value: tag.id, data: tag },
            ]);
          }

          setSelected([...selected, data]);
        }}
        onDeselect={(t) => {
          setSelected([...selected?.filter((tag) => tag?.value !== t)]);
        }}
        onClear={() => setSelected([])}
        value={selected}
        defaultValue={initialData?.map((item) => item.id) || []}
        showSearch
        className="w-full"
        allowClear
        placeholder="Начните вводить название тега"
        filterOption={false}
        onSearch={fetchData}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
      />
    </Form.Item>
  );
}
