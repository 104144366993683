import { Settings, OfflineWidget } from "@modules";
import { Breadcrumb } from "@components";

export default function SettingsPage() {
  return (
    <div className="container max-content">
      <Breadcrumb data={[{ title: "Настройки сайта", href: "/settings" }]} />
      <h1 className="mb-5 lg:mb-8 text-xl lg:text-3xl">Изменить настройки</h1>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Settings.Global />
        </div>
      </div>
      <OfflineWidget />
    </div>
  );
}
