import Title from "./title";
import MetaTitle from "./metatitle";
import Description from "./description";
import Tags from "./tags";
import Content from "./content";
import { Components } from "@modules";
import { useState, useEffect } from "react";

export default function Details(props) {
  const [components, setComponents] = useState(
    props?.form?.getFieldValue(["data", "components"]) || {}
  );

  useEffect(() => {
    props.form.setFieldsValue({
      data: {
        ...props.form.getFieldValue("data"),
        components,
      },
    });
  }, [components]);

  const onComponentsChange = (_components) => {
    setComponents(_components);
  };

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Title {...props} />
      </div>
      <div className="col-span-12">
        <MetaTitle {...props} />
      </div>
      <div className="col-span-12">
        <Description {...props} />
      </div>
      <div className="col-span-12">
        <Tags {...props} />
      </div>
      <div className="col-span-12 mb-3">
        <Content {...props} />
      </div>
      <div className="col-span-12 p-5 border border-solid border-neutral-200 rounded-md">
        <span className="block w-full font-medium text-center text-xl">
          Модули страницы
        </span>
        <Components.Editor
          key="components"
          data={components}
          onChange={onComponentsChange}
          className="m-0"
        />
      </div>
    </div>
  );
}
