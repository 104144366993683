module.exports = {
  env: process.env.NODE_ENV || process.env.REACT_APP_ENV || "production",
  port: process.env.PORT || process.env.REACT_APP_PORT || "3001",
  api: process.env.REACT_APP_API_ENDPOINT,
  staticApi: process.env.REACT_APP_STATIC_API_ENDPOINT,
  frontend: process.env.REACT_APP_FRONTEND_ENDPOINT,
  basepath: process.env.REACT_APP_BASEPATH,
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_SITEKEY,
  revalidateSecret: process.env.REACT_APP_REVALIDATE_SECRET,
  antd: {
    theme: {
      token: {
        colorPrimary: "#283348",
        colorPrimaryBg: "#E7E9EB",
        colorInfo: "#283348",
      },
    },
  },
  s3Url: process.env.REACT_APP_S3_PUBLIC_URL,
};
