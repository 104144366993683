import { Select, Form } from "antd";
import { useEffect, useState } from "react";
import * as API from "@api";

export default function FormInput(params) {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Recursively fetch all users
    const fetch = async (page = 1) => {
      const response = await API.Users.getAll({ page, limit: 1000 });
      const users = response?.data?.rows || [];
      const next = response?.data?.next || null;

      setData((prev) => [
        ...prev,
        ...users.map((val) => ({
          label: `ID ${val.id}: ${val?.name}`,
          value: val.id,
        })),
      ]);

      if (next) {
        fetch(page + 1);
      }
    };

    fetch();
  }, []);

  return (
    <Form.Item
      {...params}
      name="user_id"
      className="w-full"
      label="Внутренний пользователь"
    >
      <Select
        className="w-full"
        placeholder="Выберите пользователя"
        allowClear
        showSearch
        filterOption={(input, option) =>
          option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={data}
        {...params}
      />
    </Form.Item>
  );
}
