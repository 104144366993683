import { Settings, OfflineWidget } from "@modules";
import { Breadcrumb } from "@components";
import { useParams } from "react-router-dom";

export default function SettingsPage() {
  const { type } = useParams();

  return (
    <div className="container max-content">
      <Breadcrumb
        data={[
          { title: "Страницы сайта", href: "/settings/pages" },
          {
            title: "Редактировать страницу",
            href: `/settings/pages/edit/${type}`,
          },
        ]}
      />
      <h1 className="mb-5 lg:mb-8 text-xl lg:text-3xl">
        Редактировать страницу
      </h1>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Settings.Pages.Edit type={type} />
        </div>
      </div>
      <OfflineWidget />
    </div>
  );
}
