import React, { createContext, useContext, useEffect, useState } from "react";
import { Auth } from "@api";
import { message } from "antd";

const AuthContext = createContext({
  currentUser: null,
  login: () => Promise,
  logout: () => Promise,
  resetPassword: () => Promise,
  loading: false,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function checkAuth() {
      Auth.getAuth()
        .then((res) => {
          if (res?.data && !res?.error) {
            setCurrentUser(res?.data?.user || null);
          }
        })
        .then(() => setLoading(false));
    }

    checkAuth();
  }, []);

  async function login({ email, password, token = null }) {
    if (!token) {
      return message.error(
        "Ошибка проверки reCAPTCHA, пожалуйста, попробуйте еще раз",
        2
      );
    }

    setLoading(true);
    const user = await Auth.signin({ email, password, token });
    setLoading(false);

    if (user.error || !user) {
      return message.error("Неверные данные входа, попробуйте еще раз", 2);
    }

    setCurrentUser({
      email: user?.data?.user?.email || null,
      name: user?.data?.user?.name || null,
      id: user?.data?.user?.id || null,
      role: user?.data?.user?.role || null,
    });
  }

  async function resetPassword(data) {
    setLoading(true);

    Auth.resetPass(data)
      .then(() => {
        message.info("Инструкция восстановления пароля направлена на почту", 2);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function logout() {
    setLoading(true);
    await Auth.logout();
    setCurrentUser(null);
    setLoading(false);
  }

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    loading,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
