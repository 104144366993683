// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ruRU: {
    messages: {
      ui: {
        blockTunes: {
          toggler: {
            "Click to tune": "Нажмите, чтобы настроить",
            "or drag to move": "или перетащите",
          },
        },
        inlineToolbar: {
          converter: {
            "Convert to": "Конвертировать в",
          },
        },
        toolbar: {
          toolbox: {
            Add: "Добавить",
          },
        },
        popover: {
          Filter: "Поиск",
          "Nothing found": "Ничего не найдено",
        },
      },
      toolNames: {
        Text: "Параграф",
        Heading: "Заголовок",
        List: "Список",
        Warning: "Примечание",
        Checklist: "Чеклист",
        Quote: "Цитата",
        Code: "Код",
        Delimiter: "Разделитель",
        "Raw HTML": "HTML-фрагмент",
        Table: "Таблица",
        Link: "Ссылка",
        Marker: "Маркер",
        Bold: "Полужирный",
        Italic: "Курсив",
        InlineCode: "Моноширинный",
        Image: "Фото",
        ImageQuote: "Цитата с фото",
      },

      tools: {
        warning: {
          Title: "Название",
          Message: "Сообщение",
        },
        link: {
          "Add a link": "Вставьте ссылку",
        },
        list: {
          Ordered: "Нумерованный",
          Unordered: "Ненумерованный",
        },
        stub: {
          "The block can not be displayed correctly.":
            "Блок не может быть отображен",
        },
        embed: {
          "Enter a caption": "Введите подпись",
        },
        table: {
          "With headings": "С заголовками",
          "Without headings": "Без заголовков",
          "Add column to left": "Добавить столбец слева",
          "Add column to right": "Добавить столбец справа",
          "Add row above": "Добавить строку сверху",
          "Add row below": "Добавить строку снизу",
          Heading: "Заголовок",
        },
        heading: {
          "Heading 1": "Заголовок 1",
          "Heading 2": "Заголовок 2",
          "Heading 3": "Заголовок 3",
          "Heading 4": "Заголовок 4",
          "Heading 5": "Заголовок 5",
          "Heading 6": "Заголовок 6",
        },
        quote: {
          "Align Left": "По левому краю",
          "Align Center": "По центру",
        },
        carousel: {
          Caption: "Подпись",
        },
      },
      blockTunes: {
        delete: {
          Delete: "Удалить",
          "Click to delete": "Удалить блок",
        },
        moveUp: {
          "Move up": "Переместить вверх",
        },
        moveDown: {
          "Move down": "Переместить вниз",
        },
        quote: {
          "Align Left": "Выровнять по левому краю",
        },
      },
    },
  },
};
