import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import * as API from "@api";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    API.Categories.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return () =>
          message.error(
            "Ошибка во время получения данных, попробуйте позже",
            2
          );
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);
    const meta = form.getFieldValue("meta") || null;

    if (values?.color_code?.toHexString) {
      values.color_code = values.color_code.toHexString();
    }

    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Categories.update({
      id,
      ...normalizedValues,
      meta,
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(
            res?.message || "Ошибка при сохранении данных, попробуйте позже"
          );
        }

        const seo = form.getFieldValue("seo") || null;

        if (seo) {
          await API.Meta.update({
            ...seo,
            url: `/${form.getFieldValue("slug")}`,
          }).catch((err) =>
            message.error("Ошибка при сохранении SEO-записи", 2)
          );
        }

        return message.success("Данные обновлены", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) =>
        message.error(
          err.message || "Ошибка при сохранении данных, попробуйте позже",
          2
        )
      )
      .finally(() => setLoading(false));
  };

  const onDelete = async (values) => {
    setLoading(true);

    API.Categories.destroy({
      id,
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(
            res?.message || "Ошибка при удалении данных, попробуйте позже"
          );
        }

        return message.success(
          "Данные удалены",
          1,
          () => (window.location.href = "/categories")
        );
      })
      .catch((err) =>
        message.error(
          err.message || "Ошибка при удалении данных, попробуйте позже",
          2
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
    >
      <DataForm
        form={form}
        onDelete={onDelete}
        data={data}
        loading={loading}
        onSave={onSave}
      />
    </Form>
  );
}
