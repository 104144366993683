import { Form, Select } from "antd";

export default function FormInput(props) {
  return (
    <Form.Item
      {...props}
      name="role"
      className="w-full"
      label="Роль"
      rules={[
        {
          required: true,
          message: "Пожалуйста, выберите значение",
        },
      ]}
    >
      <Select
        placeholder="Выберите значение"
        className="w-full"
        showSearch={false}
        allowClear={false}
        options={[
          {
            label: "Администратор",
            value: "admin",
          },
          {
            label: "Редактор",
            value: "editor",
          },
        ]}
      />
    </Form.Item>
  );
}
