import axios from "axios";
import qs from "qs";
import config from "@config";

const api = axios.create({
  baseURL: config.api,
});

// Axios request interceptor
api.interceptors.request.use(
  async (config) => {
    config.headers = {
      ...config.headers,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };

    config.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: "brackets",
        encode: false,
      });
    };

    config.withCredentials = true;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
