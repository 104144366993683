import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Loading } from "@components";
import { message, Form } from "antd";
import DataForm from "./form";
import dayjs from "dayjs";
import * as API from "@api";

export default function EditData() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [exitPrompt, setExitPrompt] = useState(false);
  const [data, setData] = useState(null);
  const [form] = Form.useForm();
  const editorInstance = useRef(null);

  useEffect(() => {
    API.Articles.getOne({ id }).then((res) => {
      if (res.error || !res) {
        return () =>
          message.error("Unable to load data, please try again later.", 2);
      }

      if (res.data.published_at) {
        res.data.published_at = dayjs(res.data.published_at);
      }

      setData(res.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // if exitPrompt is true, prevent user from accidentally leaving the page
    // by showing Modal.prompt

    const handler = (e) => {
      e.preventDefault();
      e.returnValue = "";
    };

    if (exitPrompt) {
      window.addEventListener("beforeunload", handler);

      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }

    return () => {};
  }, [exitPrompt]);

  if (!data) {
    return <Loading compact />;
  }

  const onSave = async (values) => {
    setLoading(true);
    let content = null;

    if (editorInstance?.current) {
      content = (await editorInstance.current.save()) || null;
    }

    if (data?.type_id === 7) {
      const blocks = form.getFieldValue("blocks") || [];
      content = {
        time: Date.now(),
        blocks,
        version: "custom",
      };
    }

    const meta = form.getFieldValue("meta") || null;
    const image = form.getFieldValue("image") || null;
    const tts = form.getFieldValue("tts") || null;
    const authors = form.getFieldValue("authors") || [];
    const people = form.getFieldValue("people") || [];
    const tags = form.getFieldValue("tags")?.map((tag) => tag?.data) || [];
    const translated_article_id =
      form.getFieldValue("translated_article_id") || null;
    const slot_params = form.getFieldValue("slot_params") || null;
    const nextStatus = form.getFieldValue("next_status") || null;
    const planned_at = form.getFieldValue("planned_at") || null;

    if (!content || !content.blocks || !content.blocks.length) {
      setLoading(false);
      return message.error("Материал не может быть пустым", 2);
    }

    if (!image) {
      setLoading(false);
      return message.error("Необходимо добавить изображение", 2);
    }

    const normalizedValues = Object.entries(values).reduce(
      (acc, [key, value]) => {
        acc[key] = value !== undefined ? value : null;
        return acc;
      },
      {}
    );

    API.Articles.update({
      id,
      ...normalizedValues,
      meta,
      content,
      image,
      tts,
      authors,
      people,
      tags,
      translated_article_id,
      slot_params,
      planned_at,
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(
            res?.message || "Ошибка при сохранении материала, попробуйте позже"
          );
        }

        // await API.Revalidate.staticPages();

        // // Old article revalidate
        // await API.Revalidate.articles({ slug: data?.slug });

        // // Check if title changed
        // if (data?.slug !== res?.data?.slug) {
        //   await API.Revalidate.articles({ slug: res?.data?.slug });
        // }

        const seo = form.getFieldValue("seo") || null;

        if (seo) {
          await API.Meta.update({
            ...seo,
            url: `/${form.getFieldValue("category")?.slug}/${id}/${
              res?.data?.slug
            }.html`,
          }).catch((err) =>
            message.error("Ошибка при сохранении SEO-записи", 2)
          );
        }

        if (nextStatus === "planned") {
          const scheduled = await API.Articles.schedule({ id });

          if (scheduled?.error || scheduled instanceof Error) {
            return message.error(
              scheduled?.message ||
                "Ошибка во время планирования материала, попробуйте позже",
              2
            );
          }

          return message.success("Материал сохранен и запланирован", 2, () =>
            window.location.reload()
          );
        }

        if (nextStatus === "published") {
          const published = await API.Articles.publish({ id });

          if (published?.error || published instanceof Error) {
            return message.error(
              published?.message ||
                "Ошибка во время публикации материала, попробуйте позже",
              2
            );
          }

          return message.success(
            "Материал успешно отправлен на публикацию",
            2,
            () => window.location.reload()
          );
        }

        if (nextStatus === "in_review") {
          const inReview = await API.Articles.preview({ id });

          if (inReview?.error || inReview instanceof Error) {
            return message.error(
              inReview?.message ||
                "Ошибка во время отправки материала на проверку, попробуйте позже",
              2
            );
          }

          return message.success(
            "Материал успешно отправлен на проверку",
            2,
            () => window.location.reload()
          );
        }

        if (nextStatus === "cancel_publish") {
          const cancelled = await API.Articles.cancelSchedule({ id });

          if (cancelled?.error || cancelled instanceof Error) {
            return message.error(
              cancelled?.message ||
                "Ошибка во время отмены публикации, попробуйте позже",
              2
            );
          }

          return message.success(
            "Материал снят с запланированной публикации",
            2,
            () => window.location.reload()
          );
        }

        if (nextStatus === "unpublished") {
          const unpublished = await API.Articles.unpublish({ id });

          if (unpublished?.error || unpublished instanceof Error) {
            return message.error(
              unpublished?.message ||
                "Ошибка во время снятия с публикации, попробуйте позже",
              2
            );
          }

          return message.success("Материал снят с публикации", 2, () =>
            window.location.reload()
          );
        }

        setLoading(false);

        return message.success("Материал сохранен", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) => {
        setLoading(false);

        return message.error(
          err.message || "Ошибка при сохранении материала, попробуйте позже",
          2
        );
      });
  };

  const onPublish = () => {
    onSave({
      ...form.getFieldsValue(),
      status: "published",
    });
  };

  const onUnpublish = async () => {
    setLoading(true);
    API.Articles.update({
      id,
      status: "draft",
    })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error unpublishing article");
        }

        // await API.Revalidate.staticPages();

        // // Old article revalidate
        // await API.Revalidate.articles({ slug: data?.slug });

        return message.success("Article unpublished", 1, () =>
          window.location.reload()
        );
      })
      .catch((err) =>
        message.error(err.message || "Error unpublishing article", 2)
      )
      .finally(() => setLoading(false));
  };

  const onDelete = () => {
    setLoading(true);

    API.Articles.destroy({ id })
      .then(async (res) => {
        if (res?.error || !res?.data) {
          throw new Error(res?.message || "Error deleting article");
        }

        // await API.Revalidate.staticPages();

        // // Old article revalidate
        // await API.Revalidate.articles({ slug: data?.slug });

        return message.success(
          "Article deleted successfully",
          1,
          () => (window.location.href = "/articles")
        );
      })
      .catch((err) =>
        message.error(err.message || "Error deleting article", 2)
      );
  };

  return (
    <Form
      form={form}
      onFinish={onSave}
      onFinishFailed={(err) =>
        err?.errorFields?.map((msg) => message.error(msg.errors[0], 2))
      }
      layout="vertical"
      className="w-full form-container"
      initialValues={data}
      // if form input is changed, console log
      onValuesChange={() => !exitPrompt && setExitPrompt(true)}
    >
      <DataForm
        onDelete={onDelete}
        form={form}
        data={data}
        loading={loading}
        onSave={onSave}
        onPublish={onPublish}
        onUnpublish={onUnpublish}
        editorInstance={editorInstance}
      />
    </Form>
  );
}
