import { Tag } from "antd";
import { useEffect, useState } from "react";
import Name from "./name";
import Slug from "./slug";
import User from "./user";
import * as API from "@api";

export default function Details(props) {
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    API.Locales.getAll().then((res) =>
      setLocales(res?.data?.rows?.filter((locale) => locale?.code !== "ru"))
    );
  }, []);

  return (
    <div className="grid grid-cols-12 gap-3">
      <div className="col-span-12">
        <Tag>ID {props?.form?.getFieldValue("id")}</Tag>
      </div>
      <div className="col-span-12">
        <Name {...props} />
      </div>
      <div className="col-span-12">
        <Slug {...props} />
      </div>
      <div className="col-span-12">
        <User {...props} />
      </div>
    </div>
  );
}
