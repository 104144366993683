import React from "react";
import pkg from "@base/package.json";
import { Layout, Menu, Dropdown, Avatar } from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "@contexts";
import {
  TeamOutlined,
  UserOutlined,
  GlobalOutlined,
  LogoutOutlined,
  DashboardOutlined,
  SettingOutlined,
  TagsOutlined,
  PicLeftOutlined,
  ProfileOutlined,
  FileImageOutlined,
  LineChartOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

const { Header, Content, Footer, Sider } = Layout;

export default function Moderator({ children, page, ...props }) {
  const { currentUser } = useAuth();

  return (
    <Layout hasSider={false} style={{ minHeight: "100vh" }}>
      <Header
        className="bg-white shadow-sm flex justify-between items-center p-5 z-50"
        style={{
          height: 70,
          position: "fixed",
          // top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="flex justify-start items-center">
          <img
            src="/img/logo.svg"
            className="block h-12"
            alt="Mustafin Magazine"
          />
        </div>
        <Dropdown
          menu={{
            className: "min-w-44 max-w-xs",
            items: [
              {
                key: "profile",
                type: "group",
                label: (
                  <div className="w-full p-0">
                    <span className="break-words block mb-1 w-full text-neutral-400 font-normal text-xs">
                      ID {currentUser.id} &middot; {currentUser.email}
                    </span>
                  </div>
                ),
              },
              {
                key: "divider",
                type: "divider",
              },
              {
                key: "settings",
                icon: <SettingOutlined />,
                label: (
                  <Link to={`/users/edit/${currentUser?.id}`}>
                    Настройки профиля
                  </Link>
                ),
              },
              {
                key: "divider",
                type: "divider",
              },
              {
                key: "logout",
                icon: <LogoutOutlined />,
                label: (
                  <Link className="transition-none" to="/logout">
                    Выйти
                  </Link>
                ),
                danger: true,
              },
            ],
          }}
        >
          <div className="flex items-center">
            <Avatar
              style={{ cursor: "pointer" }}
              icon={<UserOutlined />}
              size="large"
            />
            <div className="flex flex-col justify-center items-start ml-3 text-left cursor-pointer">
              <span className="text-base font-medium text-slate-900 leading-none m-0">
                {currentUser?.name}
              </span>
              <span className="text-sm text-neutral-400 leading-none mt-1">
                Администратор
              </span>
            </div>
          </div>
        </Dropdown>
      </Header>
      <Layout
        hasSider={false}
        className="ml-16 lg:ml-52"
        style={{
          marginTop: 70,
          backgroundColor: "#fafafb",
        }}
      >
        <Sider
          className="bg-white pt-5"
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            bottom: 0,
          }}
          breakpoint="lg"
          collapsedWidth="64"
          width={208}
        >
          <div
            style={{
              height: 32,
              margin: 16,
            }}
          />
          <Menu
            mode="inline"
            selectedKeys={[page]}
            defaultOpenKeys={["dict", "blog"]}
            items={[
              {
                key: "dashboard",
                icon: <DashboardOutlined />,
                label: (
                  <Link className="transition-none" to="/">
                    Главная
                  </Link>
                ),
              },
              {
                key: "articles",
                icon: <PicLeftOutlined />,
                label: (
                  <Link className="transition-none" to="/articles">
                    Материалы
                  </Link>
                ),
              },
              {
                key: "authors",
                icon: <UsergroupAddOutlined />,
                label: (
                  <Link className="transition-none" to="/authors">
                    Авторы
                  </Link>
                ),
              },
              {
                key: "projects",
                icon: <GlobalOutlined />,
                label: (
                  <Link className="transition-none" to="/projects">
                    Спецпроекты
                  </Link>
                ),
              },
              {
                key: "categories",
                icon: <ProfileOutlined />,
                label: (
                  <Link className="transition-none" to="/categories">
                    Рубрики
                  </Link>
                ),
              },
              {
                key: "tags",
                icon: <TagsOutlined />,
                label: (
                  <Link className="transition-none" to="/tags">
                    Теги
                  </Link>
                ),
              },
              {
                key: "people",
                icon: <SolutionOutlined />,
                label: (
                  <Link className="transition-none" to="/people">
                    Персоны
                  </Link>
                ),
              },
              {
                key: "media",
                icon: <FileImageOutlined />,
                disabled: true,
                label: "Медиа",
              },
              {
                key: "settings",
                icon: <SettingOutlined />,
                label: "Настройки",
                children: [
                  {
                    key: "global",
                    label: (
                      <Link className="transition-none" to="/settings">
                        Настройки сайта
                      </Link>
                    ),
                  },
                  {
                    key: "pages",
                    label: (
                      <Link className="transition-none" to="/settings/pages">
                        Страницы
                      </Link>
                    ),
                  },
                  {
                    key: "contacts",
                    label: (
                      <Link className="transition-none" to="/settings/contacts">
                        Контакты
                      </Link>
                    ),
                    disabled: true,
                  },
                  {
                    key: "agreement",
                    label: (
                      <Link
                        className="transition-none"
                        to="/settings/agreement"
                      >
                        Соглашения
                      </Link>
                    ),
                    disabled: true,
                  },
                  {
                    key: "editorial",
                    label: (
                      <Link
                        className="transition-none"
                        to="/settings/editorial"
                      >
                        Редакция
                      </Link>
                    ),
                    disabled: true,
                  },
                  {
                    key: "advertising",
                    label: (
                      <Link
                        className="transition-none"
                        to="/settings/advertising"
                      >
                        Реклама
                      </Link>
                    ),
                    disabled: true,
                  },
                ],
              },
              {
                key: "meta",
                icon: <LineChartOutlined />,
                disabled: true,
                label: (
                  <Link className="transition-none" to="/meta">
                    SEO
                  </Link>
                ),
              },
              {
                key: "users",
                icon: <TeamOutlined />,
                label: (
                  <Link className="transition-none" to="/users">
                    Пользователи
                  </Link>
                ),
              },
            ]}
          />
        </Sider>
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div className="bg-none p-0 md:lg-p-3 lg:p-6">{children}</div>
        </Content>
        <Footer
          style={{
            backgroundColor: "#fafafb",
            textAlign: "center",
          }}
        >
          Mustafin Magazine © {new Date().getFullYear()} | Разработан{" "}
          <a
            href="https://musan.kz"
            alt="Musan Digital"
            title="Musan Digital"
            rel="noreferrer"
            target="_blank"
          >
            Musan Digital
          </a>{" "}
          | Версия {pkg.version}
        </Footer>
      </Layout>
    </Layout>
  );
}
