import { List, message, Tag } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ProfileOutlined } from "@ant-design/icons";
import * as API from "@api";

export default function CategoriesList(props) {
  const id = props?.form?.getFieldValue("object_id");
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getTableData();
  }, []);

  async function getTableData(params, _, sorter) {
    setLoading(true);

    const _res = await API.Categories.getAll({
      filter: {
        id,
      },
      limit: 1,
    });

    if (!_res.data) {
      return message.error(
        "Ошибка во время загрузки материалов, попробуйте позже",
        2
      );
    }

    setTableData(_res.data.rows);
    setLoading(false);
  }

  return (
    <List
      loading={loading}
      dataSource={tableData}
      onChange={getTableData}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            title={
              <div className="flex flex-col">
                <Link
                  to={`/categories/edit/${item?.id}`}
                  className="text-base font-semibold block leading-snug text-black"
                >
                  {item?.name}
                </Link>
              </div>
            }
            description={
              <Tag icon={<ProfileOutlined />} color="blue">
                Рубрика
              </Tag>
            }
          />
        </List.Item>
      )}
    />
  );
}
