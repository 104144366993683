import { List, Button, Divider, Popconfirm } from "antd";
import {
  PlusOutlined,
  SwapOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { findIndex } from "ramda";
import NewWidget from "../new";
import EditWidget from "../edit";
import * as API from "@api";

export default function WidgetList({
  blocks = [],
  widgets,
  onChange,
  ...props
}) {
  const [modalNewOpen, setModalNewOpen] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);
  const [items, setItems] = useState([]);
  const [settings, setSettings] = useState({});
  const [positionIdx, setPositionIdx] = useState(null);

  useEffect(() => {
    Promise.all([
      API.Settings.getOne("global"),
      API.Settings.getOne("maps"),
      API.Settings.getOne("nav"),
    ]).then(([global, maps, nav]) => {
      setSettings({
        global: global?.data || null,
        maps: maps?.data || null,
        nav: nav?.data || null,
      });
    });
  }, []);

  useEffect(() => {
    if (blocks?.length > 0) {
      setItems(blocks || []);
    }
  }, [blocks]);

  useEffect(() => {
    onChange(items);
  }, [items]);

  const triggerModalNew = () => setModalNewOpen(!modalNewOpen);
  const triggerModalEdit = (data = null) => setModalEdit(data || null);

  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const onItemRemove = (key) => {
    const newItems = items.filter((item, index) => index !== key);
    setItems(newItems);
  };

  return (
    <div>
      <SortableList
        key={props?.locale}
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        <List
          dataSource={items}
          renderItem={(item, key) => {
            return (
              <SortableItem key={key}>
                <List.Item className="p-0 list-none">
                  <div className="w-full grid grid-cols-12 gap-4 bg-white relative">
                    <div className="col-span-12 flex justify-between items-center">
                      <span className="block text-base w-full text-black font-medium my-5">
                        {item?.name}
                      </span>
                      <div className="flex items-center">
                        <SwapOutlined
                          className="text-neutral-400 cursor-grab hover:text-neutral-700"
                          style={{ rotate: "90deg" }}
                        />
                        <Divider type="vertical" />
                        <Button
                          icon={<EditOutlined />}
                          type="link"
                          className="m-0 p-0 h-fit w-fit"
                          onClick={() => triggerModalEdit(item)}
                        />
                        <Divider type="vertical" />
                        <Popconfirm
                          cancelText="Отмена"
                          onConfirm={() => onItemRemove(key)}
                          okText="Удалить"
                          title="Удалить элемент безвозвратно?"
                        >
                          <Button
                            danger
                            type="link"
                            className="m-0 p-0 h-fit w-fit"
                            icon={<DeleteOutlined />}
                          />
                        </Popconfirm>
                      </div>
                    </div>
                  </div>
                </List.Item>
              </SortableItem>
            );
          }}
          footer={
            <Button
              disabled={items.length >= 50}
              onClick={triggerModalNew}
              block
              icon={<PlusOutlined />}
              type="dashed"
            >
              Добавить элемент
            </Button>
          }
        />
      </SortableList>
      <NewWidget
        {...props}
        open={modalNewOpen}
        onCancel={triggerModalNew}
        widgets={widgets}
        onAfterAdd={(item) => {
          if (!positionIdx) {
            setItems([...items, { ...item, id: Date.now() }]);
            triggerModalNew();
            return;
          }

          // put item to specific position in array
          const newItems = [...items];
          newItems.splice(positionIdx, 0, { ...item, id: Date.now() });
          setItems([...newItems]);

          triggerModalNew();
        }}
      />
      <EditWidget
        {...props}
        item={modalEdit}
        onCancel={(e) => {
          e.preventDefault();
          triggerModalEdit();
        }}
        widgets={widgets}
        onAfterEdit={(item) => {
          const idx = findIndex((i) => i.id === item.id, items);
          const newItems = [...items];
          newItems[idx] = item;
          setItems([...newItems]);
          triggerModalEdit();
        }}
      />
    </div>
  );
}
