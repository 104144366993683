import {
  Card,
  Button,
  Modal,
  Input,
  Space,
  Empty,
  Divider,
  message,
} from "antd";
import { PlusOutlined, LinkOutlined } from "@ant-design/icons";
import { Meta } from "@modules";
import { Breadcrumb } from "@components";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "@api";
import qs from "qs";

export default function ListPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(null);
  const [url, setUrl] = useState("");
  const [newMetaModalVisible, setNewMetaModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const setFilter = (filter) => {
    setSearchParams(
      qs.stringify(filter, {
        arrayFormat: "brackets",
        encode: false,
        skipNulls: true,
        addQueryPrefix: true,
      })
    );
  };

  const newMetaModalTrigger = () =>
    setNewMetaModalVisible(!newMetaModalVisible);

  const onUrlSearch = async () => {
    setLoading(true);
    const res = await API.Meta.search({ url });
    setLoading(false);

    if (res?.data?.id) {
      message.info(
        "SEO-запись уже существует, отредактируйте ее при необходимости"
      );
      return navigate(`/meta/edit/${res.data.id}`);
    }

    const newMeta = await API.Meta.create({ url });

    if (newMeta?.data?.id) {
      message.success("SEO-запись успешно создана");
      return navigate(`/meta/edit/${newMeta.data.id}`);
    }

    message.error(
      "Ошибка при создании SEO-записи. Пожалуйста, попробуйте вставить другую ссылку."
    );
  };

  return (
    <div className="container max-content">
      <Modal
        okText="Добавить"
        centered
        open={newMetaModalVisible}
        onCancel={newMetaModalTrigger}
        title="Новая SEO-запись"
        okButtonProps={{
          loading,
          disabled: loading || !url,
          onClick: onUrlSearch,
        }}
      >
        <Empty
          className="my-5"
          description="Введите ссылку выше, чтобы создать SEO-запись"
        />
        <Input
          disabled={loading}
          type="url"
          prefix={<LinkOutlined />}
          placeholder="Введите ссылку на страницу"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="mb-3"
        />
      </Modal>
      <Breadcrumb data={[{ title: "SEO", href: "/meta" }]} />
      <div className="flex justify-between items-center flex-wrap gap-4 mb-7">
        <h1 className="text-3xl m-0">SEO</h1>
        <Button
          onClick={newMetaModalTrigger}
          icon={<PlusOutlined />}
          type="primary"
        >
          Добавить SEO-запись
        </Button>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12">
          <Card bordered={false}>
            <Meta.List
              searchParams={qs.parse(searchParams.toString(), {
                ignoreQueryPrefix: true,
              })}
              setSearchParams={setFilter}
            />
          </Card>
        </div>
      </div>
    </div>
  );
}
